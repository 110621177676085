/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/routes";
import store from "@/store";
import { ability } from "./abilities";
import { RESOURCE } from "../routes/const";
import { REDIRECT_AFTER_LOGIN } from "../store/modules/setting/mutation-types";

Vue.use(VueRouter);

export const router = new VueRouter({
  // mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // TODO: need to check if this is still needed
  try {
    $("#aside").attr("style", "display:none").removeClass("show");
    $("#content-aside").attr("style", "display:none").removeClass("show");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").attr("style", "display:none");
  } catch (e) {
    console.warn(e);
  }
  const { homepage } = store.state.account;

  /*
   * If the user is authenticated and visits
   * a login page , redirect to the perivous page
   */
  if (to.name == "login.index" && store.state.auth.authenticated) {
    return next({
      name: from.name,
    });
  }

  if (to.matched.some((m) => m.meta.auth) && !store.state.auth.authenticated) {
    /*
     * If the user is not authenticated and visits
     * a page that requires authentication, redirect to the login page
     */

    if (to.name === RESOURCE.backoffice.nested.release_page) {
      const { name, params } = to;
      store.commit(`setting/${REDIRECT_AFTER_LOGIN}`, { name, params });
    }

    return next({
      name: "login.index",
    });
  }

  const canNavigate = to.matched.some((route) =>
    ability.can(route.meta.action || "manage", route.meta.resource)
  );

  if (!canNavigate && store.state.auth.authenticated) {
    return next({
      name: homepage,
    });
  }
  next();
});

Vue.router = router;

export default {
  router,
};
