import { RESOURCE } from "@/routes/const";

export default {
  path: "/performance",
  name: "newperformance.index",
  component: () => import("@/views/NewPerformance/Index.vue"),
  meta: {
    auth: true,
    resource: RESOURCE.finance.main,
  },
};
