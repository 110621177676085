/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import Vue from "vue";
import {
  CHECK,
  REGISTER,
  LOGIN,
  LOGOUT,
  SET_AUTHENTICATED,
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_AUTHENTICATION,
} from "./mutation-types";

export default {
  [SET_AUTHENTICATION](state, { isAuthenticated, accessToken, refreshToken }) {
    state.authenticated = isAuthenticated;
    if (isAuthenticated) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      Vue.$http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      state.accessToken = "";
      state.refreshToken = "";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      delete Vue.$http.defaults.headers.common.Authorization;
    }
  },
  [CHECK](state) {
    const token = localStorage.getItem("accessToken");
    state.authenticated = !!token;
    if (state.authenticated) {
      Vue.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  },

  [REGISTER]() {
    //
  },

  // TODO: check probably we not need it LOGIN & LOGOUT
  [LOGIN](state, { access_token, refresh_token }) {
    state.authenticated = true;
    localStorage.setItem("accessToken", access_token);
    localStorage.setItem("refreshToken ", refresh_token);
  },

  [LOGOUT](state) {
    state.authenticated = false;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    delete Vue.$http.defaults.headers.common.Authorization;
  },

  [SET_AUTHENTICATED](state, value) {
    state.authenticated = !!value;
  },
  [SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
  },

  [SET_REFRESH_TOKEN](state, refreshToken) {
    state.refreshToken = refreshToken;
  },
};
